import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './home/home.module#HomeModule' },
  { path: 'management', loadChildren: './management/management.module#ManagementModule' },
  { path: 'manager', loadChildren:'./manager/manager.module#ManagerModule'},
  { path: 'user', loadChildren: './user/user.module#UserModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }